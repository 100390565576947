import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { AuthorizationService } from "../../api/authorization/authorization.service";
import { map, Observable, Subject, takeUntil, tap } from "rxjs";
import { LoginData } from "../login-page/login-page-data/login.types";
import { LoginService } from "../login-page/login-page-data/login.service";
import { NavigationEnd, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";
import { Title } from "@angular/platform-browser";
import { BreakpointObserver } from "@angular/cdk/layout";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "mh-home-admin",
  templateUrl: "./home-admin.component.html",
  styleUrls: ["./home-admin.component.less"],
})
export class HomeAdminComponent implements OnDestroy, OnInit {
  user$!: Observable<LoginData>;
  isCollapsed = false;
  hideSider = false;
  isMobileView$: Observable<boolean>;
  private readonly destroy$ = new Subject<void>();

  height: number = document.documentElement.clientHeight;
  state = {
    open: false,
  };

  constructor(
    private loginService: LoginService,
    private authorizationService: AuthorizationService,
    private router: Router,
    @Inject(DOCUMENT) private _document: HTMLDocument,
    private titleService: Title,
    private readonly breakpointObserver: BreakpointObserver,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly translateService: TranslateService,
  ) {
    this.markAdmin();
    this.user$ = this.authorizationService.getAuthorizedUser();
    const urlParts = this.router.url.split("/");
    this.hideSider = urlParts.includes("talent-view") || urlParts.includes("mission-partner-view");

    this.isMobileView$ = this.breakpointObserver.observe(["(max-width: 992px)"]).pipe(
      map((breakpoint) => breakpoint.matches),
      tap((mobileView) => {
        this.changeDetectorRef.detectChanges();
      }),
    );
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroy$)).subscribe((event) => {
      if (event instanceof NavigationEnd) {
        const urlParts = this.router.url.split("/");
        this.hideSider = urlParts.includes("talent-view") || urlParts.includes("mission-partner-view");
      }
    });
  }

  onLogout() {
    this.loginService.logout();
  }

  toggleCollapsed(collapsed: boolean): void {
    this.isCollapsed = collapsed;
  }

  markAdmin() {
    this._document?.getElementById("appFavicon")?.setAttribute("href", "/assets/favicon-admin.ico");
    this.titleService.setTitle(this.translateService.instant("page.title", { pageName: "Admin" }));
  }

  undoMarkAdmin() {
    this._document?.getElementById("appFavicon")?.setAttribute("href", "favicon.ico");
  }

  toggleDrawer() {
    this.state.open = !this.state.open;
  }

  ngOnDestroy() {
    this.undoMarkAdmin();
    this.titleService.setTitle("MomHunting App");
    this.destroy$.next();
  }
}
